module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Icon Beauty","short_name":"Icon Beauty","description":"ICON Beauty - makijaż permanentny i kosmetologia","lang":"pl","display":"standalone","icon":"src/assets/images/icon.png","start_url":"/","prefer_related_applications":true,"background_color":"#BBD3D5","theme_color":"#56817f","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"7c6f5820adf65b49277aac0e5178f08f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
