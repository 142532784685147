// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kosmetologia-js": () => import("./../../../src/pages/kosmetologia.js" /* webpackChunkName: "component---src-pages-kosmetologia-js" */),
  "component---src-pages-makijaz-js": () => import("./../../../src/pages/makijaż.js" /* webpackChunkName: "component---src-pages-makijaz-js" */),
  "component---src-pages-szkolenia-js": () => import("./../../../src/pages/szkolenia.js" /* webpackChunkName: "component---src-pages-szkolenia-js" */)
}

